import { createDownloadLink } from "./init-web-audio-recorder";

window.folder_id = false
export default function upload_audio (file, index, progress, final = false) {

    console.log("UPLOADING to folder - " + window.folder_id);
    // if(index > 0){
    uploadFiles(window.folder_id, file, index, progress, final)
    // }
    
    // if(window.folder_id){
    //     console.log(window.folder_id);
    //     if(index > 0){
    //         uploadFiles(window.folder_id, file, index)
    //     }
    // }else {
    //     folder_create(function(f_id) {
    //         if(f_id){
    //             console.log(f_id);
    //             if(index > 0){
    //                 uploadFiles(f_id, file, index)
    //             }
    //         }
    //     })
    // }

} 


var total_tried = 0

export function folder_create (callback){

    if(gon?.test_progress?.folder_id){
        window.folder_id = gon.test_progress.folder_id
    }

    if(window.folder_id) {
        callback(folder_id)
        return;
    }

    total_tried++
    if(total_tried > 3) {
        callback(false, true)
        return
    }


    const current_user_name = gon.current_user_name;
    const exam_name = gon.js_exam_name;
    const company_name = gon.current_user_company != "" & gon.current_user_company != null ? gon.current_user_company+"-" : ""
    const email = gon?.current_user_eamil ?? ""


    const month_day = MonthDay();
    const time_now = new Date().getTime()
    // const folder_name = company_name + current_user_name + "-" + exam_name + "-" + month_day + "-" + time_now;
    const folder_name = email + "-" + exam_name + "-" + month_day + "-" + time_now;
    window.folder_name = folder_name

    var formData = new FormData();
    formData.append('folder_name', folder_name)


    $.ajax({
        url: '/create_drive_folder',
        method: 'POST',
        data: formData,
        processData: false,
        contentType: false, 
        success: function (response) {
            console.log("FOLDER CREATED");
            window.folder_id = response?.folder?.id
            callback(folder_id); 
        },
        error: function (error) {
            console.log(error);
            callback(false);
        }
    });
}

function update_status (folderId, formData, callback) {

    // var formData = new FormData();
    const exam_name = gon.js_exam_name;
    const email = gon?.current_user_eamil ?? ""
    const month_day = MonthDay();
    const time_now = new Date().getTime()
    
    const folder_name = email + "-" + exam_name + "-" + month_day + "-" + time_now;
    formData.append('folder_id', folderId);
    formData.append('folder_name', window.folder_name ?? folder_name);
    formData.append('exam_token_id', gon.js_exam_id);

    const submit = () => {
        $(".edit_exam_token").submit(function() {
            if(typeof callback == "function"){
                callback()
            }
        })
        $(".edit_exam_token").submit()
    }

    $.ajax({
        url: '/move_drive_folder',
        method: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        success: function(response) {
            console.log(response);
            submit()
        },
        error: function(error) {
            console.log(error);
            submit()
        }
    });
}

// Function to upload files one by one
// let upIndex = 0;
window.upIndex = gon?.test_progress?.audio_index ?? 0
function uploadFiles(folderId, file, index, progress, final = false) {

    console.log(file, window.upIndex, index);
    const exam_json = gon.js_exam_parts;

    const partName = exam_json && exam_json[index] ? exam_json[index][1] : "Task "+(index ? index : 1);
    // const partName = "Part "+(parseInt(upIndex) + 1);

    const audio_name = partName && partName != "" ? partName+".mp3" : "Task "+(window.upIndex + 1)+".mp3"
    console.log(audio_name);

    window.upIndex++

    var formData = new FormData();
    formData.append('audio', file.blob);
    formData.append('folder_id', folderId);
    formData.append('audio_name', audio_name);
    formData.append('exam_token_id', gon.js_exam_id);
    formData.append('audio_index', window.upIndex);
    formData.append('progress', progress);
    
    $.ajax({
        url: '/upload_drive_audios',
        method: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        xhr: function() {
            var xhr = new window.XMLHttpRequest();
            xhr.upload.addEventListener('progress', function(evt) {
                if (evt.lengthComputable) {
                    var percentComplete = (evt.loaded / evt.total) * 100;
                    console.log('Uploading: ' + percentComplete.toFixed(2) + '%');
                }
            }, false);
            return xhr;
        },
        success: function(response) {
            console.log('File ' + (index + 1) + ' uploaded');
            if (final) {
                createDownloadLink((content, filename) => {
                    const formData = new FormData();
                    formData.append("file", content, filename);
                    update_status(folderId, formData, function() {
                        console.log("all files uplaoded");
                        console.log("creating downloadable link");
                        $("#EndModal-Loader").modal('hide');
                        $("#EndModal-1").modal({
                            backdrop: "static",
                            keyboard: false,
                        });
                        return;
                    })
                })
            }

        },
        error: function(error) {
            console.log('Error uploading file:', error);
            console.log('Error uploading file');
            uploadFiles(folderId, file, index, progress);
        }
    });
}



function MonthDay() {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const currentDate = new Date();
    const month = months[currentDate.getMonth()];
    const day = currentDate.getDate();
    return `${month}-${day}`;
}